import React from "react";
import styled from "styled-components";
import { Heading } from "rebass/styled-components";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { Container } from "../../../global";
import { useLocationCountry } from "../../../../hooks";

const JobBanner = () => {
  const { isPakistan } = useLocationCountry();
  return (
    <SectionWrapper>
      <Container>
        <Flex>
          <Heading variant="inlineHeader" textAlign={["center", null, null, "left"]} mb={["2rem", null, null, 0]}>
            Join OPay and help shape the future <br /> of technology for the next billion users.
          </Heading>
          <ButtonWrapper>
            <VacanciesButton>
              {isPakistan ? <Link href="/pk/careers">See vacancies</Link> : <Link href="#">See vacancies</Link>}
            </VacanciesButton>
          </ButtonWrapper>
        </Flex>
      </Container>
    </SectionWrapper>
  );
};

export default JobBanner;

const SectionWrapper = styled.section`
  background-color: ${props => props.theme.colors.banner};
  position: relative;
`;

const Flex = styled.div`
  flex-direction: column;
  padding: 20px;

  ${({ theme }) => theme.mq.lg`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 50px 0 50px 0;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VacanciesButton = styled.div`
  border-radius: 40px;
  background-color: ${props => props.theme.colors.white.regular};
  padding: 10px;
  width: 175px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${props => props.theme.colors.secondary};

  &:hover {
    background-color: ${props => props.theme.colors.background.light};
  }
`;

const Link = styled(OutboundLink)`
  color: ${props => props.theme.colors.secondary};
  ${props => props.theme.fontSizes.lg};
  text-decoration: none;
`;
