import React from "react";

import styled from "styled-components";
import { Flex, Text } from "rebass/styled-components";

const ValuesCard = props => {
  return (
    <ValueItem>
      <ValueImg src={props.image} alt={props.title}></ValueImg>
      <Text variant="inlineHeader" as="p" mb="1rem">
        {props.title}
      </Text>
      <Text variant="body" as="span" textAlign={["center", null, "left"]}>
        {props.description}
      </Text>
    </ValueItem>
  );
};

export default ValuesCard;

const ValueItem = styled.div`
  align-items: center;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  line-height: 80%;
  margin: 0 auto;
  margin-bottom: 30px;
  padding: 24px 8px 0;
  width: 100%;

  ${({ theme }) => theme.mq.md`
    align-items: flex-start;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    line-height: 80%;
    margin: 0 auto;
    padding: 24px;
    transition: box-shadow 260ms ease-in-out;
    width: 100%;
  `}

  ${({ theme }) => theme.mq.lg`
    width: calc(100% - 26px);
  `}
`;
const ValueImg = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 8px;
  object-fit: contain;

  ${({ theme }) => theme.mq.md`
  width: 78px;
    height: 78px;
  `}
`;
