import styled from "styled-components";

const SectionWrapper = styled.section`
  background: ${props => props.theme.colors.background.dark};
  margin-bottom: 25px;
  padding: 30px 0;

  ${({ theme }) => theme.mq.lg`
  background: ${theme.colors.background.dark};
  margin-bottom: 50px;
  padding: 80px 0;
  position: relative;  
  `}
`;

const Caption = styled.h2`
  font-weight: ${props => props.theme.fontWeights.bold};
  margin: 0 auto 20px;
  text-align: center;
`;

const SubTitle = styled.p`
  text-align: center;
`;

export { SectionWrapper, Caption, SubTitle };
