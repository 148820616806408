import React from "react";
import styled from "styled-components";
import Fade from "react-reveal/Fade";
import EntrepreneurshipIcon from "../../../../images/about-us/our-values/entrepreneurship.png";
import DiversityIcon from "../../../../images/about-us/our-values/diversity.png";
import InnovationIcon from "../../../../images/about-us/our-values/innovation.png";
import CommunityIcon from "../../../../images/about-us/our-values/community.png";

import ValuesCard from "./ValuesCard";
import { useBreakpoints } from "../../../../hooks/useBreakpoints";
import { useLocationCountry } from "../../../../hooks";

const ValuesList = () => {
  const [xs, sm, md] = useBreakpoints();

  const getDelay = index => {
    if (xs) return 0;
    if (sm || md) return (index % 2) * 250;
    return (index % 4) * 250;
  };

  const { isPakistan } = useLocationCountry();

  return (
    <Flex>
      {!isPakistan
        ? values.map((value, index) => (
            <Fade bottom key={value.title} delay={getDelay(index)}>
              <ValuesCard {...value} />
            </Fade>
          ))
        : PKValues.map((value, index) => (
            <Fade bottom key={value.title} delay={getDelay(index)}>
              <ValuesCard {...value} />
            </Fade>
          ))}
    </Flex>
  );
};

export default ValuesList;

const Flex = styled.div`
  display: grid;
  grid-row-gap: 10px;

  ${({ theme }) => theme.mq.sm`
  grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
  `}

  ${({ theme }) => theme.mq.lg`
  display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 0 24px;
    grid-gap: 16px;
  `}
`;

const values = [
  {
    image: EntrepreneurshipIcon,
    title: "Entrepreneurship",
    description: "Ability to identify problems, and collaboratively solve them to create opportunities for everyone.",
  },
  {
    image: DiversityIcon,
    title: "Diversity",
    description: "At OPay we embrace our interdependence as humans, our rich cultures, and places we come from.",
  },
  {
    image: InnovationIcon,
    title: "Innovation",
    description: "We partner people with technology to create an impact-first work environment.",
  },
  {
    image: CommunityIcon,
    title: "Community",
    description: "Our approach is simple. Great people and a vibrant community, make a good company.",
  },
];

const PKValues = [
  {
    image: EntrepreneurshipIcon,
    title: "Entrepreneurship",
    description: "Ability to identify problems, and collaboratively solve them to create opportunities for everyone.",
  },
  {
    image: DiversityIcon,
    title: "Diversity",
    description:
      "At OPay Pakistan we embrace our interdependence as humans, our rich cultures, and places we come from.",
  },
  {
    image: InnovationIcon,
    title: "Innovation",
    description: "We partner people with technology to create an impact-first work environment.",
  },
  {
    image: CommunityIcon,
    title: "Community",
    description:
      "Our approach is simple. Great people and a vibrant community result in a Digitally Empowered Pakistan.",
  },
];
