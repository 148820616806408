import React from "react";

import Layout from "../../components/common/layout/layout";
import SEO from "../../components/common/layout/pkseo";
import Navigation from "../../components/common/navigation/navigation";

import Header from "../../components/sections/about-us/header";
import WhoAndWhy from "../../components/sections/about-us/who-and-why";
import OurValues from "../../components/sections/about-us/our-values";
import JobBanner from "../../components/sections/about-us/banner";
import Footer from "../../components/sections/footer";

const AboutUs = () => {
  return (
    <Layout>
      <SEO title="About OPay" />
      <Navigation background="white" />
      <Header />
      <WhoAndWhy />
      <OurValues />
      <JobBanner />
      <Footer />
    </Layout>
  );
};

export default AboutUs;
