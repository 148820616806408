import React from "react";
import { Heading, Text } from "rebass/styled-components";
import { Container } from "../../../global";
import { SectionWrapper } from "./style";

import ValuesList from "./ValuesList";

const OurValues = () => {
  return (
    <SectionWrapper>
      <Container>
        <Heading mb="2rem">Our Values</Heading>
        <Text as="p" variant="body" textAlign={["center", "center"]} mb="2rem">
          When you combine human excellence, expertise and technology, this is what you get.
        </Text>
        <ValuesList />
      </Container>
    </SectionWrapper>
  );
};

export default OurValues;
